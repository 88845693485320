.pagination {
  --pagination-border: var(--color-neutral-500);

  margin-top: var(--spacing-2xl);

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
  }

  li {
    display: none;
    align-content: center;
    width: 48px;
    height: 48px;
    border-top: 1px solid var(--pagination-border);
    border-bottom:  1px solid var(--pagination-border);
    text-align: center;

    @media (width >= 834px) {
      display: block;
    }
  }

  li:nth-child(2n+1) {
    border-right:  1px solid var(--color-neutral-500);;
    border-left: 1px solid var(--color-neutral-500);;
  }

  li:has(>a) {

    &:hover {
      border-color: var(--pagination-border);
      background-color: var(--color-neutral-300);
    }
  }

  a {
    font-size: var(--type-scale-02);
    color: var(--color-neutral-1000);
    text-decoration: none;
  }

  li[role="presentation"] {
    text-align: center;
  }

  li:has(> a[aria-current="page"]) {
    border: 1px solid var( --color-primary-600);
    background-color: var( --color-primary-600);
    color: var(--color-primary-100);

    a {
      color: var(--color-neutral-100);
    }
  }

  a[rel="prev"],
  a[rel="next"] {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  li:has(> a[rel="prev"]) {
    display: block;
    border-right: 0;
    border-left: 1px solid var(--pagination-border);

    @media (width >= 834px) {
      border-right: 1px solid var(--color-neutral-400);
    }
  }

  li:has(> a[rel="next"]) {
    display: block;
    border-right: 1px solid var(--pagination-border);
  }

  li:has(> a[aria-disabled="true"]) {

    &:hover {
      background-color: transparent;
    }

    a {
      color: var( --color-neutral-300);
    }
  }
}
