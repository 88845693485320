:root {
  background-color: var(--color-neutral-100);
  font-family: var(--font-family--text), sans-serif;
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-150);
  color: var(--color-neutral-1000);
  interpolate-size: allow-keywords;
}

h1 {
  /* display-lg */
  font-family: var(--font-family--display), sans-serif;
  font-size: var(--type-scale-12);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-105);
  letter-spacing: -1.6px;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: var(--color-neutral-400);
  text-underline-position: under;
  text-underline-offset: -7px;
}

*:focus-visible {
  outline: 2px solid var(--color-primary-600);
}
