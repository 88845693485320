.list-item-person {
  --flow-space: var(--spacing-sm); 
  --image-max-width: 400px;
  --image-height: 300px;

  .list-item-image-container {    
    align-content: end;
    max-width: var(--image-max-width);
    height: var(--image-height);
    background-color: var(--color-neutral-300); 
  }

  .list-item-image {     
    width: auto;
    margin-right: auto;   
    margin-left: auto;
  }

  .text-section > * {
    --flow-space: var(--spacing-3xs);
  }

  a {
    display: block;
    color: var(--color-primary-600);
  }
}
