.search-form {
  display: grid;
  grid-template-columns: 1fr auto;

  [type="search"] {
    padding: var(--spacing-xs);
    border: 1px solid var(--color-neutral-400);
    outline-offset: -2px;
    background-color: var(--color-neutral-100);
    font-size: var(--type-scale-04);
  }

  .button {
    --icon--size: 24px;

    padding: 18px;
  }

  .button-text {    
    padding-left: var(--spacing-2xs);
    font-size: var(--type-scale-04);
    font-weight: 100;
    letter-spacing: 1px;
  }
}
