:root {
  --color-primary-100: #FCF2F2;
  --color-primary-200: #FBCAC7;
  --color-primary-300: #F9A29C;
  --color-primary-400: #F87A71;
  --color-primary-500: #F65146;
  --color-primary-600: #D72619;
  --color-primary-700: #C3170A;
  --color-primary-800: #A91409;
  --color-primary-900: #8A1007;
  --color-primary-1000: #610C05;
  --color-secondary-100: #DAD9F0;
  --color-secondary-200: #CDCCEB;
  --color-secondary-300: #B1AEE1;
  --color-secondary-400: #8F8BD7;
  --color-secondary-500: #615ACC;
  --color-secondary-600: #4135BD;
  --color-secondary-700: #3A30A9;
  --color-secondary-800: #322992;
  --color-secondary-900: #292277;
  --color-secondary-1000: #1D1854;
  --color-neutral-100: #FDFDFC;
  --color-neutral-200: #F8F7F4;
  --color-neutral-300: #E8E5E3;
  --color-neutral-400: #D2CCC7;
  --color-neutral-500: #B9B2AC;
  --color-neutral-600: #887F77;
  --color-neutral-700: #625C57;
  --color-neutral-800: #4A443F;
  --color-neutral-900: #322D28;
  --color-neutral-1000: #1A1511;
  --color-neutral-alpha-100: #29160501;
  --color-neutral-alpha-200: #29160505;
  --color-neutral-alpha-300: #2D180610;
  --color-neutral-alpha-400: #29160521;
  --color-neutral-alpha-500: #29160533;
  --color-neutral-alpha-600: #25160954;
  --color-neutral-alpha-700: #20191370;
  --color-neutral-alpha-800: #1D161180;
  --color-neutral-alpha-900: #1C161290;
  --color-neutral-alpha-1000: #130F0C98;
  --color-neutral-inv-alpha-100: #F5F4F401;
  --color-neutral-inv-alpha-200: #F5F4F405;
  --color-neutral-inv-alpha-300: #F5F4F410;
  --color-neutral-inv-alpha-400: #F5F4F421;
  --color-neutral-inv-alpha-500: #F5F4F433;
  --color-neutral-inv-alpha-600: #F5F4F454;
  --color-neutral-inv-alpha-700: #F5F4F470;
  --color-neutral-inv-alpha-800: #F5F4F480;
  --color-neutral-inv-alpha-900: #F5F4F490;
  --color-neutral-inv-alpha-1000: #F5F4F498;
  --color-red-100: #FEF2F1;
  --color-red-200: #FEE3E1;
  --color-red-300: #FECBC8;
  --color-red-400: #FDA29B;
  --color-red-500: #F97167;
  --color-red-600: #F04438;
  --color-red-700: #F04438;
  --color-red-800: #B42318;
  --color-red-900: #912018;
  --color-red-1000: #7A271A;
  --color-yellow-100: #FFFAEB;
  --color-yellow-200: #FEF0C8;
  --color-yellow-300: #FEDF8B;
  --color-yellow-400: #FEC94D;
  --color-yellow-500: #FDB021;
  --color-yellow-600: #F78F08;
  --color-yellow-700: #DD6903;
  --color-yellow-800: #B54708;
  --color-yellow-900: #91370D;
  --color-yellow-1000: #7B2F0E;
  --color-green-100: #EDFDF3;
  --color-green-200: #D1FADF;
  --color-green-300: #A4F4C4;
  --color-green-400: #6DE9A7;
  --color-green-500: #34D585;
  --color-green-600: #12B569;
  --color-green-700: #039654;
  --color-green-800: #027947;
  --color-green-900: #05613B;
  --color-green-1000: #054D30;
}

