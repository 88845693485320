:root {
  --blocks-container-sm: 843px;
  --blocks-container-md: 1280px;
  --blocks-container-lg: 1280px;
  --blocks-spacing-sm: var(--spacing-2xs);
  --blocks-spacing-md: var(--spacing-sm);
  --blocks-spacing-lg: var(--spacing-lg);
  --blocks-spacing-xl: var(--spacing-xl);
  --blocks-spacing-xxl: var(--spacing-2xl);
}
