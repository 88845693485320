.blocks-button-links--wrapper.blocks-button-links--wide {
  display: grid;
  grid-template-columns: subgrid;
  grid-column: full;
  padding: var(--spacing-lg);

  .blocks-button-links {
    grid-column: feature;
  }
}

.blocks-button-links--narrow {
  grid-column: feature;
  padding: var(--spacing-xl);
}

.blocks-button-links {

  .content-links--top {
    gap: var(--spacing-xs);
  }

  .content-links--items {
    gap: var(--spacing-xs);
  }
}
