.site-secondary-menu {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: var(--spacing-lg);
  gap: var(--spacing-sm);

  ul {
    align-self: stretch;
    padding: 0;
    list-style-type: none;
  }

  [data-menu-level="0"] > li {
    border-top: 1px solid var(--color-theme-border-default);
    padding-block: var(--spacing-xs);

    > a {
      display: block;
      font-weight: var(--font-weight-500);
      padding-block: var(--spacing-3xs);

      &::after {
        content: "";
        display: inline-block;
        float: right;
        width: 1lh;
        height: 1lh;
        background-color: var(--color-accent-text-default);
        vertical-align: top;
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: center;
        mask-image: var(--icon--arrow-long-right);
      }
    }
  }

  [data-menu-level="1"] {
    display: none;
  }

  /* Mobile */

  @media (width < 1440px) {
    max-height: calc(100vh - var(--site-header--height) - var(--spacing-sm));
  }

  /* Desktop */

  @media (width >= 1440px) {

    [data-menu-level="1"] {
      display: block;
    }

    [data-menu-level="1"] > li > a {
      display: inline-block;
      font-size: var(--type-scale-02);
      font-weight: var(--font-weight-400);
      padding-block: var(--spacing-3xs);
    }

    > ul {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
      gap: var(--spacing-lg);
    }
  }
}
