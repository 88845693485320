@font-face {
  font-family: "Haas Grotesk Display Web";
  src: url("../fonts/NeueHaasGroteskDisplay-65Medium-Web.woff2") format("woff2"),
       url("../fonts/NeueHaasGroteskDisplay-65Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Haas Grotesk Display Web";
  src: url("../fonts/NeueHaasGroteskDisplay-66MediumItalic-Web.woff2") format("woff2"),
       url("../fonts/NeueHaasGroteskDisplay-66MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Haas Grotesk Text Web";
  src: url("../fonts/NeueHaasGroteskText-55Roman-Web.woff2") format("woff2"),
       url("../fonts/NeueHaasGroteskText-55Roman-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Haas Grotesk Text Web";
  src: url("../fonts/NeueHaasGroteskText-56Italic-Web.woff2") format("woff2"),
       url("../fonts/NeueHaasGroteskText-56Italic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Haas Grotesk Text Web";
  src: url("../fonts/NeueHaasGroteskText-65Medium-Web.woff2") format("woff2"),
       url("../fonts/NeueHaasGroteskText-65Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Haas Grotesk Text Web";
  src: url("../fonts/NeueHaasGroteskText-66MediumItalic-Web.woff2") format("woff2"),
       url("../fonts/NeueHaasGroteskText-66MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Haas Grotesk Text Web";
  src: url("../fonts/NeueHaasGroteskText-75Bold-Web.woff2") format("woff2"),
       url("../fonts/NeueHaasGroteskText-75Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Haas Grotesk Text Web";
  src: url("../fonts/NeueHaasGroteskText-76BoldItalic-Web.woff2") format("woff2"),
       url("../fonts/NeueHaasGroteskText-76BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}
