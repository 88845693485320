.button-links-item {
  --content-links-item--background-color: var(--color-theme-surface-default);
  --content-links-item--border-color: var(--color-theme-border-default);
  --content-links-item--border: 1px solid var(--content-links-item--border-color);
  --content-links-item--arrow-background: var(--color-theme-surface-default);
  --content-links-item--arrow-color: var(--color-accent-text-default);

  display: grid;
  grid-template-rows: auto 1fr;
  border: var(--content-links-item--border);
  background-color: var(--content-links-item--background-color);
  transition: background-color 200ms ease, border 200ms ease;

  @supports selector(:has(*)) {

    &:not(:has(.button-links-item--illustration), :has(.button-links-item--text)) {
      grid-template-rows: 1fr;
    }

    &:has(> .button-links-item--html) {

      .button-links-item--link {
        border-bottom: var(--content-links-item--border);
      }
    }

    &:has(.button-links-item--link:hover) {
      --content-links-item--background-color: var(--color-theme-surface-hover);
      --content-links-item--border-color: var(--color-theme-border-hover);
      --content-links-item--arrow-background: var(--color-primary-600);
      --content-links-item--arrow-color: var(--color-neutral-100);
    }

    &:has(.button-links-item--link:focus) {
      outline: 2px solid var(--color-primary-600);
    }
  }

  .button-links-item--link {
    display: grid;
    grid-template:
      "title arrow"
      "text text"
      / 1fr auto;
    width: 100%;
    color: currentcolor;
    text-decoration: none;

    @supports selector(:has(*)) {

      &:focus {
        outline: none;
      }

      &:has(.button-links-item--illustration) {
        grid-template:
          "illustration illustration" auto
          "title arrow" auto
          "text text" 1fr
          / 1fr auto;
      }

      &:not(:has(.button-links-item--illustration), :has(.button-links-item--text)) {
        grid-template:
          "title arrow" / 1fr auto;
      }
    }
  }

  .button-links-item--title {
    display: flex;
    align-items: center;
    grid-area: title;
    padding-left: var(--spacing-sm);
  }

  .button-links-item--link:hover .button-links-item--title {
    text-decoration: underline;
  }

  .button-links-item--illustration {
    --content-links-item--illustration-min-height: 300px;
    --content-links-item--illustration-max-height: 350px;

    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: illustration;
    min-height: var(--content-links-item--illustration-min-height);
    padding: var(--spacing-sm) var(--spacing-xl);
    border-bottom: var(--content-links-item--border);

    img {
      max-height: var(--content-links-item--illustration-max-height);
      margin: auto;
    }
  }

  .button-links-item--illustration-portrait,
  .button-links-item--illustration-even {
    padding: var(--spacing-sm) var(--spacing-3xl);
  }

  .button-links-item--arrow {
    display: flex;
    align-items: center;
    grid-area: arrow;
    padding: 12px;
    border-left: var(--content-links-item--border);
    background-color: var(--content-links-item--arrow-background);

    .icon {
      --icon--color: var(--content-links-item--arrow-color);
      --icon--size: 40px;
    }
  }

  .button-links-item--text {
    grid-area: text;
    padding: var(--spacing-sm);
    border-top: var(--content-links-item--border);
  }

  .button-links-item--html {
    padding: var(--spacing-sm);

    a {
      font-size: var(--type-scale-02);
      color: var(--color-theme-text-subtle);

      &:hover {
        color: var(--color-theme-text-default);
      }
    }
  }
}
