.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  overflow: hidden;
  width: 1px;
  height: 1px;
}

.focus-visible:focus-visible {
  left: var(--spacing-xs);
  top: var(--spacing-xs);
  width: auto;
  height: auto;
  padding: var(--spacing-xs);
  background-color: var(--color-neutral-100);
  color: currentcolor
}
