.site-footer {
  background-color: var(--color-neutral-1000);
  font-size: var(--type-scale-02);
  color: var(--color-neutral-100);
  padding-block: var(--spacing-xl);

  .footer-logo {
    --footer-logo-height: 40px;

    img {
      max-height: var(--footer-logo-height);
    }
  }

  h3 {
    /* label-sm */
    font-size: var(--type-scale-02);
    font-weight: var(--font-weight-500);
    line-height: var(--line-height-150);
    color: var(--color-primary-500);
  }

  ul:not([class]) {
    padding-left: 0;
    list-style: none;

    li {
      margin-top: var(--spacing-xs);
    }

    li:has(a):has(img) {
      display: flex;
      margin-top: var(--spacing-md);
      gap: var(--spacing-sm);

      img {
        width: 24px;
      }
    }
  }

  a {
    font-weight: var(--font-weight-400);
    color: var(--color-neutral-100);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .footer-columns {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 150px));
    margin-bottom: var(--spacing-xl);
    gap: var(--spacing-xl);

    @media (width >= 834px) {
      grid-template-columns: minmax(100px, auto) repeat(auto-fit, minmax(100px, 150px));
    }
  }

  .footer-bottom-row {
    display: grid;
    grid-template-columns: 1fr;

    @media (width >= 834px) {
      align-content: end;
      grid-template-columns: 1fr 1fr;
    }
  }

  .copyright-text {
    align-content: end;
    margin-top: var(--spacing-sm);
    color: var(--color-neutral-500);

    @media (width >= 1440px) {
      text-align: right;
    }
  }
}
