.blocks-content-links {
  display: flex;
  flex-direction: column;
  width: 100%; 
}

.content-links--top {
  display: grid;
  grid-template-areas:
  "kicker"
  "intro"
  "buttons";
  grid-template-columns: 1fr;
  gap: var(--spacing-lg);

  @media (width >= 834px) {
    grid-template-areas:
    "kicker buttons"
    "intro .";
    grid-template-columns: 1fr auto;
  }

  .kicker {
    grid-area: kicker;
  }

  .html-area {
    grid-area: intro;
  }

  .buttons {
    grid-area: buttons;
  }
}

.content-links--items {
  --min-width-content-link: 320px;

  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-lg);
  margin-top: var(--spacing-lg);

  /* Tablet */

  @media (width >= 834px) {
    grid-template-columns: repeat(auto-fit, minmax(var(--min-width-content-link), 1fr));
  }
}

.list-item {
  --flow-space: var(--spacing-xs);

  color: var(--color-theme-text-default);
  text-decoration: none;

  .list-item-image {
    width: 100%;

    @media (width >= 834px) {
      width: auto;
    }
  }
}
