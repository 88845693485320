@import url("@item-enonic-types/lib-blocks/dist/blocks-card.css");

.blocks-card {
  --content-grid-grid-column: feature;
  --blocks-card--gap: var(--spacing-md);
  --blocks-card--svg-width: auto;

  grid-column: feature;

  .blocks-card--card {
    --blocks-card--flex-direction: column-reverse;
  }

  .blocks-card--body {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
    justify-content: center;
  }

  .blocks-card--image img:not([src$=".svg"]) {
    width: auto;
    height: auto;
    aspect-ratio: unset;
  }
}

.blocks-card--image-left .blocks-card--card,
.blocks-card--image-right .blocks-card--card {
  --blocks-card--image-flex: 1 1 50%;
  --blocks-card--body-flex: 1 1 50%;
}

@media (width >= 834px) {

  .blocks-card--image-left .blocks-card--card {
    --blocks-card--flex-direction: row;
  }

  .blocks-card--image-right .blocks-card--card {
    --blocks-card--flex-direction: row-reverse;
  }
}

.blocks-card--wide {
  display: grid;
  grid-template-columns: subgrid;
  grid-column: full;
  background-color: var(--color-theme-surface-default);

  .blocks-card {
    --blocks-card--body-padding: var(--spacing-md) 0;
  }
}

.blocks-card--narrow {
  grid-column: feature;

  .blocks-card {
    --blocks-card--body-padding: var(--spacing-xl);
  }

  .blocks-card--card {
    border: 1px solid var(--color-theme-border-default);
  }

  &.blocks-card--image-right .blocks-card--image {
    /* Make image cover border except left side */
    margin: -1px -1px -1px 0;
  }

  &.blocks-card--image-left .blocks-card--image {
    /* Make image cover border except right side */
    margin: -1px 0 -1px -1px;
  }
}
