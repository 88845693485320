/* stylelint-disable selector-id-pattern */

/* Branding on the banner */

a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none;
}

/* Branding on the Privacy trigger */

#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
  display: none;
}

/* stylelint-enable selector-id-pattern */
