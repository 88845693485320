.error-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--color-theme-surface-default);

  #error-header {
    flex: 1;
    gap: var(--spacing-sm);
    padding-block: var(--spacing-2xl);
  }
}
