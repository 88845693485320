.article-header {
  --article-header--ilustration-margin-top: -5%;
  --article-header--ilustration-margin-bottom: calc(-1 * var(--spacing-2xl));

  overflow: hidden;
  background-color: var(--color-theme-surface-default);
  color: var(--color-theme-text-default);

  @media (width >= 834px) {
    --article-header--ilustration-margin-top: -15%;
    --article-header--ilustration-margin-bottom: calc(-1 * var(--spacing-2xl) - 15%);
  }

  .article-header--inner {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--spacing-lg);

    @media (width >= 834px) {
      &:has(.article-header--illustration) {
        grid-template-columns: 1fr 1fr;
      }
    }

    &:has(.blocks-media) {
      gap: var(--spacing-2xl);
    }
  }

  .article-header--illustration {
    display: flex;
    place-content: center center;

    @media (width >= 834px) {
      justify-content: end;
    }

    img {
      max-width: min(550px, 100%);
      height: auto;
      margin-top: var(--article-header--ilustration-margin-top);
      margin-bottom: var(--article-header--ilustration-margin-bottom);
    }
  }

  .article-header--logo {
    --logo-max-width: 224px;
    --logo-max-height: 80px;

    img {
      max-width: var(--logo-max-width);
      max-height: var(--logo-max-height);
    }
  }

  .article-header--text {
    --flow-space: var(--spacing-lg);

    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .article-header--intro {
    --flow-space: var(--spacing-sm);
  }

  .article-header--links,
  .article-header--tags {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-xs);
  }

  .article-header--tags a {
    color: var(--color-theme-text-subtle);
  }
}
