.newsletter-form {

  &:last-of-type {
    --default-page--component-padding: var(--spacing-2xl) 0; /* Removes padding on the bottom to snugly fit on the footer */

    margin-top: auto; /* This will push the component downwards */
    background: linear-gradient(
      to bottom,
      transparent,
      transparent 50%,
      var(--color-neutral-1000) 50%,
      var(--color-neutral-1000)
    );
  }

  .newsletter-form--inner {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
    padding: var(--spacing-2xl) var(--spacing-xl);

    > * {
      max-width: 880px;
    }
  }

  h2, h3, h4 {
    text-wrap: balance;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
    font-weight: 500;

    input {
      border: 0;
      background-color: transparent;
      color: white;

      &:focus {
        outline: 2px solid var(--color-neutral-100);
      }
    }

    input[type="text"] {
      flex: 1;
      padding-block: var(--spacing-xs);
      font-size: var(--type-scale-04);

      &::placeholder {
        opacity: 1;
        color: var(--color-neutral-100);
      }
    }

    input[type="checkbox"] {
      display: inline-block;
      position: relative;
      width: 16px;
      height: 16px;
      padding: 0;
      border: 1px solid currentcolor;
      appearance: none;
      outline-offset: 2px;

      &:checked::before {
        content: "";
        position: absolute;
        left: -4px;
        top: -6px;
        width: 24px;
        height: 24px;
        background-color: white;
        line-height: 1;
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: center;
        mask-image: var(--icon--check);
      }
    }

    label {
      display: inline-flex;
      gap: var(--spacing-2xs);
      align-items: center;
    }

    button {
      padding: var(--spacing-2xs);
      cursor: pointer;

      &:focus-visible {
        outline: 2px solid var(--color-neutral-100);
      }
    }
  }

  .newsletter-form--input {
    display: flex;
    gap: var(--spacing-2xs);
    border-bottom: 1px solid currentcolor;
  }
}
