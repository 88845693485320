.default-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  #main {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .default-page--regions {
    display: contents;
  }

  [data-portal-region="main"] {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}
