.button-link {
  --macro-button-link--direction: column;
  --flow-space: var(--spacing-lg);

  display: flex;
  flex-direction: var(--macro-button-link--direction);
  gap: var(--spacing-xs);

  @media (width >= 834px) {
    --macro-button-link--direction: row;
  }
}
