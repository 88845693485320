.button:where(:not(.theme-neutral)) {
  --button--padding-inline: var(--spacing-xs);
  --button--padding-block: var(--spacing-2xs);
  --button--font-size: var(--type-scale-02);
  --button--font-weight: var(--font-weight-500);
  --button--color: var(--color-theme-text-default);
  --button--background-color: var(--color-theme-surface-default);
  --button--icon-size: 24px;

  display: inline-grid;
  grid-template-columns: auto 1fr;
  gap: 1px;
  outline-offset: 2px;
  font-size: var(--button--font-size);
  font-weight: var(--button--font-weight);
  color: var(--button--color);
  text-decoration: none;

  &:has(.button--icon) .button--text {
    background-color: var(--button--background-color);
    padding-inline: var(--button--padding-inline);
    padding-block: var(--button--padding-block);
  }

  &:not(:has(.button--icon)) {
    background-color: var(--button--background-color);
    padding-inline: var(--button--padding-inline);
    padding-block: var(--button--padding-block);
  }

  .button--icon {
    display: flex;
    align-items: center;
    max-width: calc(var(--button--icon-size) + (var(--button--padding-block) * 2));
    padding-inline: var(--button--padding-block);
    background-color: var(--button--background-color);

    .icon {
      --icon--color: var(--button--color);
      --icon--size: var(--button--icon-size);
    }
  }

  &:is([href], button) {
    cursor: pointer;

    &:hover {
      --button--background-color: var(--color-theme-surface-hover);
    }

    &:active {
      --button--background-color: var(--color-theme-surface-active);

      transform: scale(99%);
    }

    &:focus-visible {
      outline: 2px solid var(--color-primary-600);
    }
  }
}

.button-sm {
  --button--padding-inline: var(--spacing-xs);
  --button--padding-block: var(--spacing-2xs);
  --button--font-size: var(--type-scale-02);
  --button--icon-size: 18px
}

.button-md {
  --button--padding-inline: var(--spacing-sm);
  --button--padding-block: var(--spacing-xs);
  --button--font-size: var(--type-scale-03);
  --button--icon-size: 20px;
}

.button-lg {
  --button--padding-inline: var(--spacing-md);
  --button--padding-block: var(--spacing-sm);
  --button--font-size: var(--type-scale-03);
  --button--icon-size: 24px;
}

a.button.theme-neutral {
  --button--color: var(--color-primary-600);

  display: inline-block;
  color: var(--button--color);

  &:hover {
    --button--color: var(--color-primary-700);
  }

  &:active {
    --button--color: var(--color-primary-800);
  }

  &:visited {
    --button--color: var(--color-secondary-600);
  }

  .button--text {
    font-size: var(--button--font-size);
  }

  &.button-sm {
    --button--font-size: var(--type-scale-01);
  }

  &.button-md {
    --button--font-size: var(--type-scale-02);
  }

  &.button-lg {
    --button--font-size: var(--type-scale-03);
  }

  &.button-xl {
    --button--font-size: var(--type-scale-04);
  }

  .button--icon {
    display: none;
  }
}

button:where(.reset-button) {
  all: unset;
}
