.part-article-list {

  .article-list-items {
    row-gap: var(--spacing-xl);
    grid-template-columns: repeat(auto-fit, minmax(var(--min-width-content-link), 500px));
  }

  .article-list-items:has(> :nth-child(3)) {
    grid-template-columns: repeat(auto-fit, minmax(var(--min-width-content-link), 1fr));
  }
}

.part-article-list-feature-article {
  margin-bottom: var(--spacing-xs);

  @media (width >= 834px) {
    margin-bottom: var(--spacing-3xl);
  }

  .list-item {
    /* Find best looking size for tablet breakpoint 834, substitute spacing-xl padding from parent */
    --feature-article-image-min-width: calc(400px - var(--spacing-xl));
    --feature-article-image-width: 625px;
    --feature-article-text-min: calc(370px - var(--spacing-xl));
    --flow-space: var(--spacing-md);

    @media (width >= 834px) {
      display: grid;
      grid-template-areas:
      "image ."
      "image kicker"
      "image summary"
      "image avatar"
      "image .";
      grid-template-columns: minmax(var(--feature-article-image-min-width), var(--feature-article-image-width)) minmax(var(--feature-article-text-min), 1fr);
      column-gap: var(--spacing-xl);
    }

    .list-item-image {
      grid-area: image;

      @media (width >= 834px) {
        width: auto;
      }
    }

    .avatar {
      grid-area: avatar;
    }

    .kicker {
      grid-area: kicker;
    }

    .article-summary {
      grid-area: summary;
    }
  }
}
