.breadcrumbs {

  ol {
    display: flex;
    padding: 0;
    list-style: none;
  }

  li {
    display: flex;

    &:not(:last-child)::after {
      content: "";
      display: inline-block;
      float: right;
      width: 16px;
      height: 16px;
      margin: auto 0 auto auto;
      background-color: var(--color-theme-text-subtle);
      margin-inline: 10px;
      mask-repeat: no-repeat;
      mask-size: contain;
      mask-position: center;
      mask-image: var(--icon--chevron-right);
    }
  }

  a[href] {
    color: var(--color-theme-text-subtle);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}


