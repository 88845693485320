.html-area {

  p {
    --flow-space: var(--spacing-md);

    /* text--lg */
    font-size: var(--type-scale-04);
    font-weight: var(--font-weight-400);
    line-height: var(--line-height-150);
  }

  h2 {
    --flow-space: var(--spacing-lg);

    /* heading-2xl */
    font-family: var(--font-family--display), sans-serif;
    font-size: var(--type-scale-10);
    font-weight: var(--font-weight-500);
    line-height: var(--line-height-115);
  }

  h3 {
    --flow-space: var(--spacing-lg);

    /* heading-xl */
    font-family: var(--font-family--display), sans-serif;
    font-size: var(--type-scale-09);
    font-weight: var(--font-weight-500);
    line-height: var(--line-height-120);
  }

  h4 {
    --flow-space: var(--spacing-lg);

    /* heading-lg */
    font-family: var(--font-family--display), sans-serif;
    font-size: var(--type-scale-08);
    font-weight: var(--font-weight-500);
    line-height: var(--line-height-120);
  }

  h5 {
    --flow-space: var(--spacing-lg);

    /* heading-md */
    font-family: var(--font-family--display), sans-serif;
    font-size: var(--type-scale-07);
    font-weight: var(--font-weight-500);
    line-height: var(--line-height-120);
  }

  h6 {
    --flow-space: var(--spacing-lg);

    /* heading-sm */
    font-family: var(--font-family--display), sans-serif;
    font-size: var(--type-scale-05);
    font-weight: var(--font-weight-500);
    line-height: var(--line-height-130);
  }

  a[href]:not([class]) {
    --html-area--link-color:var(--color-primary-600);

    color: var(--html-area--link-color);

    &:hover {
      --html-area--link-color: var(--color-primary-700);
    }

    &:active {
      --html-area--link-color: var(--color-primary-800);
    }

    &:focus {
      --html-area--link-color: var(--color-primary-600);
    }

    &:visited {
      --html-area--link-color: var(--color-secondary-600);
    }
  }

  ol:not([class]) {
    --flow-space: var(--spacing-md);
    --ol--counter-width: 24px;
    --ol--counter-margin-right: 16px;
    --ol--padding-left: calc(var(--ol--counter-width) + var(--ol--counter-margin-right));

    padding-left: var(--ol--padding-left);
    list-style: none;
    counter-reset: ol-li;

    > li {
      counter-increment: ol-li;

      &:not(:first-child) {
        margin-top: var(--spacing-2xs);
      }

      &::before {
        content: counter(ol-li);
        display: inline-block;
        width: var(--ol--counter-width);
        margin-right: var(--ol--counter-margin-right);
        margin-left: calc(var(--ol--padding-left) * -1);
        font-weight: var(--font-weight-500);
        color: var(--color-primary-600);
        text-align: center;
      }
    }
  }

  ul:not([class]) {
    --flow-space: var(--spacing-md);
    --ol--marker-width: 24px;
    --ol--marker-padding-left: 16px;

    padding-left: var(--ol--marker-width);
    list-style-type: "— ";


    > li { /* stylelint-disable-line */
      padding-left: var(--ol--marker-padding-left);

      &::marker {
        font-weight: var(--font-weight-500);
        color: var(--color-primary-600);
      }

      &:not(:first-child) {
        margin-top: var(--spacing-2xs);
      }
    }
  }

  figure {
    --flow-space: var(--spacing-md);
    --figure-margin-inline: 0;

    &.editor-align-right {
      float: right;
      margin-left: var(--figure-margin-inline);
    }

    &.editor-align-left {
      float: left;
      margin-right: var(--figure-margin-inline);
    }

    &.captioned.editor-align-right,
    &.captioned.editor-align-left {
      margin-bottom: var(--spacing-xs);
    }

    > figcaption {
      /* text-md */;
      font-size: var(--type-scale-02);
      font-weight: var(--font-weight-400);
      line-height: var(--line-height-150);
      color: var(--color-theme-text-subtle, var(--color-neutral-800));
    }

    @media (width >= 834px) {

      &.editor-align-left,
      &.editor-align-right {
        --figure-margin-inline: var(--spacing-md);
      }
    }

    @media (width < 834px) {
      /* This declaration needs !important to win over the style-attribute */
      width: 100% ! important;
    }
  }

  /* Provides horizontal scroll on smaller devices */

  .table-wrapper {
    --flow-space: var(--spacing-lg);

    overflow-x: auto;
    white-space: nowrap;
  }

  table {
    width: 100%;

    caption {
      /* heading-xl */
      margin-bottom: var(--spacing-md);
      font-family: var(--font-family--display), sans-serif;
      font-size: var(--type-scale-09);
      font-weight: var(--font-weight-500);
      line-height: var(--line-height-120);
      text-align: left;
    }

    th {
      /* heading-sm */
      font-family: var(--font-family--display), sans-serif;
      font-size: var(--type-scale-05);
      font-weight: var(--font-weight-500);
      line-height: var(--line-height-130);
      text-align: left;
    }

    td {
      /* text-md */
      font-size: var(--type-scale-02);
      font-weight: var(--font-weight-400);
      line-height: var(--line-height-150);
    }

    thead tr {
      border-bottom: 1px solid var(--color-neutral-800);
    }

    tbody tr:not(:last-child) {
      border-bottom: 1px solid var(--color-neutral-400);
    }

    th[scope="row"] {
      border-right: 1px solid var(--color-neutral-800);
    }

    th, td {
      padding: var(--spacing-xs);

      &:first-child {
        padding-left: 0;
      }
    }
  }
}
