.global-search--wrapper {
  background-color: var(--color-neutral-200);
}

.global-search {
  --global-search--search-input-min-width: 200px;

  display: flex;
  flex-direction: column;
}

.global-search input[type="search"] {
  min-width: var(--global-search--search-input-min-width);
  background-color: var(--color-neutral-100);
}

.global-search-results {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-lg);
  gap: var(--spacing-lg);
}

.global-search-hits-result-text {
  padding-bottom: var(--spacing-xs);
}

.global-search-hits-result-text.with-border {
  border-bottom: 1px solid var(--color-neutral-400);
}

.global-search-hits-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  width: 100%;

  @media (width >= 834px) {
    width: 65%;
  }
}

.global-search-hit-link {
  display: flex;
  flex-direction: column;
  color: var(--color-neutral-1000);
  text-decoration: none;
  gap: var(--spacing-2xs);
}

.global-search-hit-title {
  text-decoration: none;
}

.global-search-hit-link:hover .global-search-hit-title {
  text-decoration: underline;
}
