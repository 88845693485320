.avatar {
  display: grid;
  align-items: center;
  grid-template-areas:
    "image name"
    "image text";
  grid-template-columns: auto minmax(0, 1fr);
  grid-column-gap: var(--spacing-xs);

  img {
    grid-area: image;
    max-height: 40px;
  }

  .avatar--name {
    grid-area: name;
    font-weight: var(--font-weight-500);
  }

  .avatar--date,
  .avatar--text {
    grid-area: text;
  }
}

.avatar--one-row {
  display: flex;
  gap: var(--spacing-2xs);
  justify-content: flex-start;

  img {
    max-height: 24px;
  }
}
