/* Add 80px spacing as padding on all direct children of content-grid  */

.content-grid > * {
  padding-block: var(--spacing-2xl);
}

/* Content-grid for blocks should should have 40px,  */

.blocks.content-grid > * {
  padding-block: var(--spacing-lg);
}

/* Footer should not have the padding spacing */

.site-footer.content-grid > * {
  padding-block: 0;
}

/* Card block should not have the padding spacing */

.blocks-card--wrapper {
  padding-block: 0 !important;
}

/* Themes that has a next sibling with same theme should not add spacing in bottom */

.theme-neutral:has(+ .theme-neutral):not([class*="card"], .blocks-factbox-bos, .button),
.theme-accent:has(+ .theme-accent):not([class*="card"], .blocks-factbox-bos, .button),
.theme-brand1:has(+ .theme-brand1):not([class*="card"], .blocks-factbox-bos, .button),
.theme-brand2:has(+ .theme-brand2):not([class*="card"], .blocks-factbox-bos, .button),
.theme-brand3:has(+ .theme-brand3):not([class*="card"], .blocks-factbox-bos, .button) {
  margin-bottom: 0;
  padding-bottom: 0;
}

/* Card block that is narrow should have spacing, but with margin instaed of padding  */

.blocks.content-grid >.blocks-card--narrow {
  padding-block: 0;
  margin-block: var(--spacing-lg);
}

/* Neutral color in header should not have spacing bottom  */
.article-header.theme-neutral > .article-header--inner {
  padding-bottom: 0;
}

/* First block should have 80px spacing on top  */

.blocks.content-grid >*:not([class*="card"], .blocks-button-links--wrapper, .blocks-factbox-bos):first-child {
  padding-top: var(--spacing-2xl);
}

/* Last block should have 80px spacing on bottom  */

.blocks.content-grid >*:not([class*="card"], .blocks-button-links--wrapper, .blocks-factbox-bos):last-child {
  padding-bottom: var(--spacing-2xl)
}

/* Card narrow should have 80px margin spacing on top  */

.blocks.content-grid >.blocks-card--narrow:first-child, .blocks.content-grid >.blocks-factbox-bos:first-child{
  margin-top: var(--spacing-2xl);
}

/* Card narrow should have 80px margin on bottom  */

.blocks.content-grid >.blocks-card--narrow:last-child, .blocks.content-grid >.blocks-factbox-bos:last-child {
  margin-bottom: var(--spacing-2xl);
}

/* Blocks that has not a theme class set but next sibling has theme should have 80px padding bottom */

.blocks.content-grid >*:not([class*="theme"]):has(+ [class*="theme"]) {
  padding-bottom: var(--spacing-2xl);
}

/* Blocks that has not a theme class set but previous sibling has theme should have 80px padding top */

.blocks.content-grid > *[class*="theme"] + *:not([class*="theme"]) {
  padding-top: var(--spacing-2xl);
}

/* Blocks that has a different theme should have 80px in the bottom */

.blocks.content-grid > .theme-neutral:not(:has(+ .theme-neutral), [class*="card"], .blocks-factbox-bos), .blocks.content-grid > .theme-accent:not(:has(+ .theme-accent), [class*="card"], .blocks-factbox-bos), .blocks.content-grid > .theme-brand1:not(:has(+ .theme-brand1), [class*="card"], .blocks-factbox-bos), .blocks.content-grid > .theme-brand2:not(:has(+ .theme-brand2), [class*="card"], .blocks-factbox-bos) , .blocks.content-grid > .theme-brand3:not(:has(+ .theme-brand3), [class*="card"], .blocks-factbox-bos) {
  padding-bottom: var(--spacing-2xl);
}
