.site-header {
  --site-header--height: 80px;
  --site-haeder--logo-height: 40px;

  background-color: var(--color-theme-surface-default);
  border-bottom: 1px solid var(--color-theme-border-default);

  .site-header--inner {
    display: flex;
    align-items: center;
    min-height: var(--site-header--height);
    gap: var(--spacing-sm);

    #home-link {
      display: flex;
      flex-shrink: 0;
      margin-right: auto;

      img {
        height: var(--site-haeder--logo-height);
      }
    }
  }

  [popovertarget="main-menu"] {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--spacing-lg);
    height: var(--spacing-lg);
    transition: background-color 100ms ease-out;
    cursor: pointer;

    .icon {
      transition: background-color 100ms ease-out;
    }
  }

  /* If #mainmenu is open, change the button icon and color */

  :has(#main-menu[popover]:popover-open) [popovertarget="main-menu"] {
    background-color: var(--color-accent-text-default);

    .icon {
      --icon--url: var(--icon--x-mark);
      --icon--color: var(--color-theme-surface-default);
    }
  }

  /* Fallback for popover polyfill */

  @supports not (selector(:has(*))) {

    [popovertarget="main-menu"][aria-expanded="true"] {
      background-color: var(--color-accent-text-default);

      .icon {
        --icon--url: var(--icon--x-mark);
        --icon--color: var(--color-theme-surface-default);
      }
    }
  }

  #main-menu[popover] {
    padding: 0;
    border: 0;
    background-color: var(--color-theme-surface-default);
  }

  .main-menu-nav {
    --main-menu--direction: column;
    --main-menu--padding-inline: var(--content-grid--padding-inline);
    --main-menu--link-padding: var(--spacing-sm) var(--spacing-2xs);

    display: flex;
    flex-direction: var(--main-menu--direction);
    padding-inline: var(--main-menu--padding-inline);

    > button,
    > a {
      display: flex;
      padding: var(--main-menu--link-padding);
      outline-offset: -2px; /* The [popover] clips the offset on the edges, so we need to keep it inside */
      font-size: var(--type-scale-03);
      font-weight: var(--font-weight-500);
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;
    }

    a {
      color: currentcolor;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &[aria-current="page"] {
        color: var(--color-accent-text-default);
      }
    }
  }

  .search-form {
    padding-block: var(--spacing-sm);
  }

  /* Mobile / Tablet */

  @media (width < 1440px) {

    #main-menu[popover] {
      top: var(--site-header--height);
      width: 100vw;
      height: calc(100dvh - var(--site-header--height));
    }

    .hidden-mobile {
      display: none;
    }

    .main-menu-nav {
      width: 100%;

      --main-menu--link-border: 1px solid var(--color-theme-border-default);
      --main-menu--icon-size: 24px;

      > * {
        border-bottom: var(--main-menu--link-border);
      }

      > button {
        display: block;
        line-height: var(--main-menu--icon-size);

        &::after {
          content: "";
          float: right;
          width: 1lh;
          height: 1lh;
          background-color: var(--color-accent-text-default);
          vertical-align: top;
          mask-repeat: no-repeat;
          mask-size: contain;
          mask-position: var(--icon--position, center);
          mask-image: var(--icon--arrow-long-right);
        }
      }
    }
  }

  /* Desktop */

  @media (width >= 1440px) {

    .hidden-desktop {
      display: none;
    }

    #main-menu[popover] {
      display: flex;
      position: relative ! important;
    }

    .main-menu-nav {
      --main-menu--padding-inline: 0;
      --main-menu--link-padding: var(--spacing-2xs) 12px;
      --main-menu--direction: row;

      display: flex;
      align-items: center;
      flex-wrap: wrap;
      position: relative;
      height: var(--site-header--height);

      > button,
      > a {
        display: flex;
        padding: var(--main-menu--link-padding);
        font-weight: var(--font-weight-500);
        line-height: 1;
        white-space: nowrap;
        color: currentcolor;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }

        &[aria-current="page"] {
          color: var(--color-accent-text-default);
        }
      }

      button[popovertarget]::after {
        transition: transform 100ms ease-out;
      }
    }

    /* When secondary menu is open / The has-plus selector targets the next sibling */

    [popovertarget]:has(+ .secondary-menu[popover]:popover-open) {
      text-decoration: underline;

      &::after {
        transform: rotate(-180deg);
      }
    }

    /* Fallback for popover polyfill */

    @supports not (selector(:has(*))) {

      [popovertarget^="secondary-menu"][aria-expanded="true"] {
        text-decoration: underline;

        &::after {
          transform: rotate(-180deg);
        }
      }
    }

    .secondary-menu[popover] {
      left: 50%;
      top: var(--site-header--height);
      width: min(1280px, 100% - (2 * var(--content-grid--padding-inline)));
      max-height: calc(100vh - var(--site-header--height));
      border: 1px solid var(--color-theme-border-default);
      transform: translateX(-50%) translateY(-100vh);
      transition: transform 300ms, display 300ms allow-discrete;

      &:popover-open {
        transform:  translateX(-50%) translateY(0);

        @starting-style {
          transform: translateX(-50%) translateY(-100vh);
        }
      }

      /* Fallback for popover-polyfill */

      &.\:popover-open {
        transform:  translateX(-50%) translateY(0);
      }
    }
  }

  /* Mobile animation – move in from right side (both main and secondary menus) */

  @media (width < 1440px) {

    [popover] {
      opacity: 0;
      transform: translateX(100vh);
      transition: opacity 0.2s, transform 0.2s, display 0.2s allow-discrete;

      &:popover-open {
        opacity: 1;
        transform: translateX(0);

        @starting-style {
          opacity: 0;
          transform: translateX(100vh);
        }
      }

      /* Fallback for popover-polyfill */

      &.\:popover-open {
        opacity: 1;
        transform: translateX(0);
      }
    }

    .secondary-menu[popover] {
      left: calc(var(--main-menu--padding-inline));
      top: calc(var(--site-header--height) - 1px);
      overflow-y: auto;
      width: min(1280px, 100% - (2 * var(--main-menu--padding-inline)) + 2px);
      border: 1px solid var(--color-theme-border-default);
    }
  }
}
