.footer-actions  {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex: 1;

  padding-block: var( --spacing-md);
  border-top: 1px solid var(--color-neutral-400);

  .action-link {
    display: flex;
    color: var(--color-neutral-600);

    &:hover {
      color: var(--color-neutral-1000);
    }
  }
}
