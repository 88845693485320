.blocks-video {
  position: relative;

  .cover-image {
    width: 100%;
    height: auto;
  }

  .play-indicator {
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    background-color: rgb(255 255 255 / 60%);
    transform: translateX(-50%) translateY(-50%);

    .icon {
      font-size: 50px;

      @media (width >= 834px) {
        font-size: 100px;
      }
    }
  }

  .video {
    position: relative;
    height: 0;
    padding-bottom: 56.25%; /* 16:9 */

    iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}
