:root {
  --icon--check: url("../../images/check.svg");
  --icon--chevron-down: url("../../images/chevron-down.svg");
  --icon--chevron-right: url("../../images/chevron-right.svg");
  --icon--chevron-up: url("../../images/chevron-up.svg");
  --icon--arrow-down-tray: url("../../images/arrow-down-tray.svg");
  --icon--arrow-long-left: url("../../images/arrow-long-left.svg");
  --icon--arrow-long-right: url("../../images/arrow-long-right.svg");
  --icon--arrow-right: url("../../images/arrow-right.svg");
  --icon--arrow-top-right-on-square: url("../../images/arrow-top-right-on-square.svg");
  --icon--bars-3: url("../../images/bars-3.svg");
  --icon--magnifying-glass: url("../../images/magnifying-glass.svg");
  --icon--x-mark: url("../../images/x-mark.svg");
  --icon--document: url("../../images/document.svg");
  --icon--play-circle: url("../../images/play-circle.svg");
  --icon-share: url("../../images/share.svg")
}

.icon,
.icon-start::before,
.icon-end::after {
  content: "";
  display: inline-block;
  width: var(--icon--width, var(--icon--size, 1.5em));
  height: var(--icon--height, var(--icon--size, 1.5em));

  @supports (width: 1lh) {
    width: var(--icon--width, var(--icon--size, 1lh));
    height: var(--icon--height, var(--icon--size, 1lh));
  }

  background-color: var(--icon--color, currentcolor);
  vertical-align: top;
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: var(--icon--position, center);
  mask-image: var(--icon--url);
}

.icon {
  --icon--position: center;
}

.icon-start::before {
  --icon--position: left;

  margin-right: var(--icon--margin, 0.5ch);
}

.icon-end::after {
  --icon--position: right;

  margin-left: var(--icon--margin, 0.5ch);
}

.icon--accent {
  --icon--color: var(--color-accent-text-default);
}

.icon--arrow-down-tray {
  --icon--url: var(--icon--arrow-down-tray);
}

.icon--arrow-long-left {
  --icon--url: var(--icon--arrow-long-left);
}

.icon--arrow-long-right {
  --icon--url: var(--icon--arrow-long-right);
}

.icon--arrow-right {
  --icon--url: var(--icon--arrow-right);
}

.icon--arrow-top-right-on-square {
  --icon--url: var(--icon--arrow-top-right-on-square);
}

.icon--check {
  --icon--url: var(--icon--check);
}

.icon--bars-3 {
  --icon--url: var(--icon--bars-3);
}

.icon--chevron-down {
  --icon--url: var(--icon--chevron-down);
}

.icon--chevron-right {
  --icon--url: var(--icon--chevron-right);
}

.icon--chevron-up {
  --icon--url: var(--icon--chevron-up);
}

.icon--magnifying-glass {
  --icon--url: var(--icon--magnifying-glass);
}

.icon--x-mark {
  --icon--url: var(--icon--x-mark);
}

.icon--document {
  --icon--url: var(--icon--document);
}

.icon--play-circle {
  --icon--url: var(--icon--play-circle);
}

.icon--share {
  --icon--url: var(--icon-share);
}
