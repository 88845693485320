.kicker {
  display: flex;
  flex-direction: column;
  gap: var(--kicker-spacing, var(--spacing-xs));

  .kicker--text {
    --kicker--text-color :var(--color-accent-text-default, var(--color-primary-600));

    display: flex;
    align-items: center;
    gap: var(--spacing-2xs);
    font-weight: var(--font-weight-700);
    color: var(--kicker--text-color);

    &::after {
      content: "";
      display: inline-block;
      width: 32px;
      border-top: 2px solid var(--kicker--text-color);
    }
  }

  .kicker--no-stripe {
    width: 0;
    border: 0;
  }
}
