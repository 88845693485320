:root {
  /* Mobile */
  --spacing-3xs: 4px;
  --spacing-2xs: 6px;
  --spacing-xs: 12px;
  --spacing-sm: 20px;
  --spacing-md: 24px;
  --spacing-lg: 32px;
  --spacing-xl: 48px;
  --spacing-2xl: 64px;
  --spacing-3xl: 112px;

  /* Tablet */

  @media (width >= 834px) {
    --spacing-3xs: 4px;
    --spacing-2xs: 6px;
    --spacing-xs: 12px;
    --spacing-sm: 20px;
    --spacing-md: 24px;
    --spacing-lg: 32px;
    --spacing-xl: 56px;
    --spacing-2xl: 72px;
    --spacing-3xl: 96px;
  }

  /* Desktop */

  @media (width >= 1440px) {
    --spacing-3xs: 4px;
    --spacing-2xs: 8px;
    --spacing-xs: 16px;
    --spacing-sm: 20px;
    --spacing-md: 32px;
    --spacing-lg: 40px;
    --spacing-xl: 64px;
    --spacing-2xl: 80px;
    --spacing-3xl: 112px;
  }

  /* Wide */

  @media (width >= 2000px) {
    --spacing-3xs: 4px;
    --spacing-2xs: 8px;
    --spacing-xs: 16px;
    --spacing-sm: 24px;
    --spacing-md: 32px;
    --spacing-lg: 40px;
    --spacing-xl: 64px;
    --spacing-2xl: 80px;
    --spacing-3xl: 112px;
  }
}

