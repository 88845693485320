.supplier-grid {
  --supplier-grid--cell-size-preferred: 213px;
  --supplier-grid--cell-size-minimum: 180px;
  --supplier-grid--cell-border-width: 1px;

  display: grid;
  grid-template-areas:
    "kicker link"
    "intro  link"
    "cards cards";
  grid-template-columns: 1fr auto;
  background-color: var(--color-theme-surface-default);
  gap: var(--spacing-xs);

  .kicker {
    grid-area: kicker;
  }

  .intro {
    grid-area: intro;
    max-width: 590px;
  }

  .see-all-link {
    grid-area: link;
  }

  @media (width <= 834px) {
    display: flex;
    flex-direction: column;
  }
}

.supplier-grid--container {
  display: grid;
  grid-area: cards;
  grid-template-columns: repeat(auto-fit, calc(var(--supplier-grid--cell-size-preferred) - var(--supplier-grid--cell-border-width)));
  gap: var(--supplier-grid--cell-border-width);
  width: 100%;
  margin: 0 auto;
  padding-top: var(--spacing-sm);

  @media (width <= 834px) {
    justify-content: center;
    grid-template-columns: 1fr 1fr;
  }
}

.supplier-grid--supplier-illustration {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--supplier-grid--cell-size-preferred);
  outline: 1px solid var(--color-theme-border-hover);
  text-align: center;
  transition: background-color 200ms ease, border 200ms ease;

  &:focus {
    outline: 2px solid var(--color-primary-600);
    background-color:var(--color-neutral-100);
  }

  &:hover {
    background-color:var(--color-neutral-100);
  }

  img {
    width: 146px;
    height: auto;
  }

  @media (width <= 550px) {
    height: auto;
    min-height: 140px;
    padding: 30px;

    img {
      width: auto;
    }
  }
}

.supplier-logo-portrait {
  padding: var(--spacing-xl);
}

.supplier-logo-even {
  padding: var(--spacing-xs) var(--spacing-xl);
}
