/* Mobile devices */

@media (width <= 834px) {

  .d-none-sm {
    display: none;
  }
}

/* Tablet devices */

@media (width <= 1440px) {

  .d-none-md {
    display: none;
  }
}

/* Desktop devices */

@media (width <= 2000px) {

  .d-none-lg {
    display: none;
  }
}
