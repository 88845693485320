.content-grid {
  --content-grid--padding-inline: var(--spacing-sm);
  --content-grid--full: minmax(var(--content-grid--padding-inline), 1fr);
  --content-grid--content: min(var(--blocks-container-sm), 100% - var(--content-grid--padding-inline) * 2);
  --content-grid--breakout: minmax(0, calc((var(--blocks-container-md) - var(--blocks-container-sm))));

  display: grid;
  grid-template-columns:
    [full-start] var(--content-grid--full)
    [feature-start breakout-start content-start] var(--content-grid--content) [content-end]
    var(--content-grid--breakout) [ breakout-end feature-end]
    var(--content-grid--full) [full-end];

  /* Tablet */

  @media (width >= 834px) {
    --content-grid--padding-inline: var(--spacing-xl);
  }
}

.content-grid > * {
  grid-column: var(--content-grid-grid-column, content);
}

.site-header.content-grid >* {
  padding-block: 0;
}

.content-grid--content {
  --content-grid-grid-column: content;
}

.content-grid--breakout {
  --content-grid-grid-column: breakout;
}

.content-grid--feature {
  --content-grid-grid-column: feature;
}

.content-grid--full {
  --content-grid-grid-column: full;
}

@media (width < 834px) {

  .content-grid--full--sm {
    --content-grid-grid-column: full;
  }
}

