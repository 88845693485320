.form-view {
  --forms--error-color: var(--color-red-800);

  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);

  a[href] {
    --html-area--link-color:var(--color-primary-600);

    color: var(--html-area--link-color);

    &:hover {
      --html-area--link-color: var(--color-primary-700);
    }

    &:active {
      --html-area--link-color: var(--color-primary-800);
    }

    &:focus {
      --html-area--link-color: var(--color-primary-600);
    }

    &:visited {
      --html-area--link-color: var(--color-secondary-600);
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
    padding: var(--spacing-lg);
    background-color: var(--color-neutral-200);
  }

  .forms--control {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-3xs);
  }

  select {
    appearance: none;
    background-image: var(--icon--chevron-down);
    background-repeat: no-repeat;
    background-position: center right var(--spacing-xs);
    background-size: 1em;
  }

  textarea,
  input,
  select {
    --forms--input-border-color: var(--color-neutral-400);
    --forms--input-border-width: 1px;

    padding: var(--spacing-xs);
    border: var(--forms--input-border-width) solid var(--forms--input-border-color);
    background-color: var(--color-neutral-100);
  }

  legend,
  label {
    font-size: var(--type-scale-03);
    font-weight: var(--font-weight-700);
  }

  fieldset {
    padding: 0;
    border: var(--forms--input-border-width, 0) solid var(--forms--input-border-color, transparent);

    legend {
      margin-bottom: var(--spacing-3xs);
    }

    label {
      font-size: inherit;
      font-weight: inherit;
    }
  }

  [data-has-error="true"] input[type="text"],
  [data-has-error="true"] textarea,
  fieldset[data-has-error="true"] {
    --forms--input-border-color: var(--forms--error-color);
    --forms--input-border-width: 2px;
  }

  input[type="checkbox"] {
    appearance: none;
    display: grid;
    width: 16px;
    height: 16px;
    padding: 0;

    &::before {
      content: var(--icon--check);
      width: 11px;
      height: 10px;
      transform: scale(0) translate(2px, -6px);
      transition: 100ms transform ease-in-out;
    }

    &:checked::before {
      transform: scale(1) translate(2px, -6px);
    }
  }

  input[type="radio"] {
    display: grid;
    place-content: center;
    width: 16px;
    height: 16px;
    padding: 0;
    border: 1px solid var(--forms--input-border-color);
    border-radius: 50%;
    background-color: var(--color-neutral-100);
    vertical-align: top;
    appearance: none;

    &::before {
      content: "";
      width: 0.5em;
      height: 0.5em;
      border-radius: 50%;
      box-shadow: inset 1em 1em var(--color-neutral-1000);
      transform: scale(0);
      transition: 100ms transform ease-in-out;
    }

    &:checked::before {
      transform: scale(1);
    }
  }

  button {
    --button--background-color: var(--color-neutral-1000);

    all: unset;
    align-self: flex-start;
    padding: var(--spacing-xs) var(--spacing-lg);
    outline-offset: 2px;
    background-color: var(--button--background-color);
    color: var(--color-neutral-100);
    cursor: pointer;

    &:hover {
      --button--background-color: var(--color-neutral-900);
    }

    &:active {
      --button--background-color: var(--color-neutral-800);
    }

    &:focus-visible {
      outline: 2px solid var(--color-primary-600);
    }
  }

  .forms--radiobutton-option,
  .forms--checkbox-option {
    display: flex;
    gap: var(--spacing-xs);
    align-items: center;
    margin-bottom: var(--spacing-3xs);
  }

  @media (width >= 834px) {

    .forms--checkbox-options:has(.forms--checkbox-option:nth-child(6)) {
      columns: 2;
    }
  }

  .forms--error-text {
    margin-top: var(--spacing-xs);
    font-size: var(--type-scale-03);
    font-weight: var(--font-weight-700);
    color: var(--forms--error-color);
  }

  .form-view--success {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
    padding: var(--spacing-lg);
    background-color: var(--color-neutral-200);
  }

  .forms--optional {
    font-weight: var(--font-weight-400);

    &::before {
      content: "(";
    }

    &::after {
      content: ")";
    }
  }
}
