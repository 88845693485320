.global-search-hit-breadcrumb {
  --global-search--breadcrumb-divider-height: 16px;
  --global-search--breadcrumb-divider-margin-top: 2px;

  display: flex;
  font-size: var(--type-scale-02);  
  font-weight: var(--font-weight-400);
  color: var(--color-neutral-600);
}

.global-search-hit-breadcrumb-item { 
  display: flex;    
}

.global-search-hit-breadcrumb-divider {
  height: var(--global-search--breadcrumb-divider-height);
  margin-top: var(--global-search--breadcrumb-divider-margin-top);   
}
