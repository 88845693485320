.theme-neutral { /* White */
  --color-theme-surface-default: var(--color-neutral-100);
  --color-theme-surface-hover: var(--color-neutral-200);
  --color-theme-surface-active: var(--color-neutral-300);
  --color-theme-text-default: var(--color-neutral-1000);
  --color-theme-text-subtle: var(--color-neutral-800);
  --color-accent-text-default: var(--color-primary-600);
  --color-theme-border-default: var(--color-neutral-400);
  --color-theme-border-hover: var(--color-neutral-400);
}

.theme-accent {
  --color-theme-surface-default: var(--color-primary-600);
  --color-theme-surface-hover: var(--color-primary-700);
  --color-theme-surface-active: var(--color-primary-800);
  --color-theme-text-default: var(--color-neutral-100);
  --color-theme-text-subtle: var(--color-neutral-100);
  --color-accent-text-default: var(--color-neutral-100);
  --color-theme-border-default: var(--color-neutral-100);
  --color-theme-border-hover: var(--color-neutral-100);
}

.theme-brand1 {
  --color-theme-surface-default: var(--color-neutral-1000);
  --color-theme-surface-hover: var(--color-neutral-900);
  --color-theme-surface-active: var(--color-neutral-800);
  --color-theme-text-default:  var(--color-neutral-100);
  --color-theme-text-subtle: var(--color-neutral-500);
  --color-accent-text-default: var(--color-primary-500);
  --color-theme-border-default: var(--color-neutral-800);
  --color-theme-border-hover: var(--color-neutral-600);

}

.theme-brand2 {
  --color-theme-surface-default: var(--color-neutral-200);
  --color-theme-surface-hover: var(--color-neutral-300);
  --color-theme-surface-active: var(--color-neutral-400);
  --color-theme-text-default: var(--color-neutral-1000);
  --color-theme-text-subtle: var(--color-neutral-800);
  --color-accent-text-default: var(--color-primary-600);
  --color-theme-border-default: var(--color-neutral-400);
  --color-theme-border-hover: var(--color-neutral-500);
}

.theme-brand3 {
  --color-theme-surface-default: var(--color-neutral-alpha-200);
  --color-theme-surface-hover: var(--color-neutral-alpha-300);
  --color-theme-surface-active: var(--color-neutral-alpha-400);
  --color-theme-text-default: var(--color-neutral-1000);
  --color-theme-text-subtle: var(--color-neutral-800);
  --color-accent-text-default: var(--color-primary-600);
}

.theme-background {
  background-color: var(--color-theme-surface-default);
  color: var(--color-theme-text-default);
}
