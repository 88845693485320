.button-links {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);

  .button-links--filter a.button {
    --button--font-weight:  var(--font-weight-400);

    &.theme-brand1 {
      --button--color: var(--color-theme-text-subtle);
    }
  }

  .button-links--items {
    --button-links--items-cols: 1;

    display: grid;
    grid-template-columns: repeat(var(--button-links--items-cols), 1fr);
    gap: var(--spacing-md);

    /* Tablet */

    @media (width >= 834px) {
      --button-links--items-cols: 2;
    }

    /* Desktop */

    @media (width >= 1140px) {
      --button-links--items-cols: 3;
    }
  }

  .button-links-item--supplier-links {
    display: flex;
    gap: var(--spacing-xs);
    flex-wrap: wrap;
  }

  .button-links-item--html:has(.button-links-item--supplier-links) {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
  }
}
