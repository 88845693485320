.blocks-quote {
  --kicker-spacing: var(--spacing-md);

  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);

  blockquote {
    --quote-spacing-adjustment: -4px;

    &::before {
      content: '"';
      margin-right: var(--quote-spacing-adjustment);
    }

    &::after {
      content: '"';
      margin-left: var(--quote-spacing-adjustment);
    }
  }

  .quote-by {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);

    .quote-image {
      max-width: 56px;
    }
  }
}
