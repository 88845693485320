@import url("@item-enonic-types/lib-blocks/dist/blocks-accordion.css");

.blocks-accordion {
  --blocks-accordion--padding: var(--spacing-sm);
  --blocks-accordion--border: 1px solid var(--color-neutral-400);
  --blocks-accordion--icon-size: var(--spacing-md);
  --blocks-accordion--flow-space: var(--spacing-xs);

  details {
    border: var(--blocks-accordion--border);

    summary {
      list-style: none;
      cursor: pointer;

      &::-webkit-details-marker {
        display: none
      }

      &:hover {
        background-color: var(--color-neutral-200);
      }

      h3 {
        /* heading-lg */
        display: grid;
        grid-template-columns: 1fr calc(var(--blocks-accordion--icon-size) + var(--blocks-accordion--padding) * 2);
        position: relative;
        font-family: var(--font-family--display), sans-serif;
        font-size: var(--type-scale-08);
        font-weight: var(--font-weight-500);
        line-height: 1;
      }

      h3::before {
        content: "";
        position: absolute;
        right: 0;
        top: calc(var(--blocks-accordion--padding) * -1);
        bottom: calc(var(--blocks-accordion--padding) * -1);
        width: calc(var(--blocks-accordion--icon-size) + var(--blocks-accordion--padding));
        border-left: var(--blocks-accordion--border);
      }

      h3::after {
        content: "";
        width: var(--blocks-accordion--icon-size);
        min-width: var(--blocks-accordion--icon-size);
        height: var(--blocks-accordion--icon-size);
        margin: auto 0 auto auto;
        background-color: var(--color-primary-600);
        transition: transform 0.2s ease;
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: center;
        mask-image: var(--icon--chevron-down);
      }
    }

    &[data-animate-open] h3::after {
      border-bottom: var(--blocks-accordion--border);
      transform: rotate(-180deg);
    }

    &[open] summary {
      border-bottom: var(--blocks-accordion--border);
      background-color: var(--color-neutral-200);
    }
  }

  &.theme-neutral {
    --blocks-accordion--flow-space: 0;
    --blocks-accordion--padding: var(--blocks-spacing-sm) 0;
  }
}
