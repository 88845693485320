:root {
  /* Font family */
  --font-family--display: haas grotesk display web;
  --font-family--text: haas grotesk text web;

  /* Font Weight */
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-700: 700;

  /* Line height */
  --line-height-100: 1;
  --line-height-105: 1.05;
  --line-height-115: 1.15;
  --line-height-120: 1.20;
  --line-height-130: 1.30;
  --line-height-150: 1.50;

  /* Font size */
  --type-scale-01: 12px;
  --type-scale-02: 14px;
  --type-scale-03: 16px;
  --type-scale-04: 18px;
  --type-scale-05: 20px;
  --type-scale-06: 22px;
  --type-scale-07: 24px;
  --type-scale-08: 26px;
  --type-scale-09: 28px;
  --type-scale-10: 32px;
  --type-scale-11: 36px;
  --type-scale-12: 40px;

  /* Tablet */

  @media (width >= 834px) {
    --type-scale-01: 12px;
    --type-scale-02: 14px;
    --type-scale-03: 16px;
    --type-scale-04: 18px;
    --type-scale-05: 20px;
    --type-scale-06: 22px;
    --type-scale-07: 24px;
    --type-scale-08: 29px;
    --type-scale-09: 35px;
    --type-scale-10: 44px;
    --type-scale-11: 58px;
    --type-scale-12: 64px;
  }

  /* Desktop and Wide */

  @media (width >= 1440px) {
    --type-scale-01: 12px;
    --type-scale-02: 14px;
    --type-scale-03: 16px;
    --type-scale-04: 18px;
    --type-scale-05: 20px;
    --type-scale-06: 22px;
    --type-scale-07: 24px;
    --type-scale-08: 30px;
    --type-scale-09: 38px;
    --type-scale-10: 48px;
    --type-scale-11: 56px;
    --type-scale-12: 80px;
  }
}

.display-lg {
  font-family: var(--font-family--display), sans-serif;
  font-size: var(--type-scale-12);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-105);
  letter-spacing: -1.6px;
}

.display-md {
  font-family: var(--font-family--display), sans-serif;
  font-size: var(--type-scale-11);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-115);
  letter-spacing: -1.12px;
}

.heading-2xl {
  font-family: var(--font-family--display), sans-serif;
  font-size: var(--type-scale-10);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-115);
}

.heading-xl {
  font-family: var(--font-family--display), sans-serif;
  font-size: var(--type-scale-09);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-120);
}

.heading-lg {
  font-family: var(--font-family--display), sans-serif;
  font-size: var(--type-scale-08);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-120);
}

.heading-md {
  font-family: var(--font-family--display), sans-serif;
  font-size: var(--type-scale-07);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-120);
}

.heading-sm {
  font-family: var(--font-family--display), sans-serif;
  font-size: var(--type-scale-05);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-130);
}

.heading-xs {
  font-family: var(--font-family--display), sans-serif;
  font-size: var(--type-scale-04);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-130);
}

.text-xl {
  font-size: var(--type-scale-04);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-150);
}

.text-lg {
  font-size: var(--type-scale-04);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-150);
}

.text-md {
  font-size: var(--type-scale-02);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-150);
}

.text-sm {
  font-size: var(--type-scale-01);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-150);
}

.label-lg {
  font-size: var(--type-scale-04);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-150);
}

.label-md {
  font-size: var(--type-scale-03);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-150);
}

.label-sm {
  font-size: var(--type-scale-02);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-150);
}

.label-xs {
  font-size: var(--type-scale-01);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-150);
}

.navigation {
  font-size: var(--type-scale-03);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-100);
  letter-spacing: 0.15px;
}

