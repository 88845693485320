.blocks-download {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.blocks-download--link-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);

  @media (width >= 360px) {
    min-width: 300px;
  }
}

.blocks-download--link {
  display: grid;
  grid-template-columns: var(--spacing-lg) auto var(--spacing-xl);
  height: auto;
  min-height: var(--spacing-xl);
  padding-left: var(--spacing-sm);
  border: 1px solid var(--color-neutral-400);
  color: var(--color-neutral-1000);
  text-decoration: none;
  transition:
    background-color 200ms ease,
    border 200ms ease;

  &:hover {
    background-color: var(--color-neutral-200);
  }
}

.block-download--page-icon-box {
  display: flex;
  justify-content: center;
  padding-top: var(--spacing-xs);
}

.blocks-download--info-container {
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  justify-content: start;
  padding-top: var(--spacing-xs);
  padding-right: var(--spacing-xs);
  padding-bottom: var(--spacing-xs);
  border-right: 1px solid var(--color-neutral-400);
}

.blocks-download--page-icon {
  margin-right: var(--spacing-3xs);
}

.blocks-download-filename {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-right: var(--spacing-3xs);
}

.blocks-download--metadata {
  color: var(--color-neutral-800);
}

.block-download--down-arrow-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--spacing-xl);
  height: inherit;
  color: var(--color-primary-600);
}
